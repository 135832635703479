import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import EmailIcon from "../../images/email.svg"
import PlaceIcon from "../../images/google-place.svg"
import PhoneIcon from "../../images/phone.svg"

import { Container } from "../global"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "rafal1" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // const handleSubmit = (event) => {
  //   event.preventDefault()
  // }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <Subtitle>magister Rafał Skolmowski</Subtitle>
            <h1>RS Fizjoterapia</h1>
            <h1>i Terapia Manualna</h1>
            <h2>Gabinet fizjoterapii i terapii manualnej w Sokółce</h2>

            <FeatureList>
              <Feature>
                <img src={PhoneIcon} alt="phoneicon" />
                <FeatureTextWrapper> 505 135 115</FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={EmailIcon} alt="emailicon" />
                <FeatureTextWrapper>
                  skolmowskifizjoterapia@gmail.com
                </FeatureTextWrapper>
              </Feature>

              <Feature>
                <img src={PlaceIcon} alt="placeicon" />{" "}
                <FeatureTextWrapper>
                  Joachima Lelewela 2/2, Sokółka
                </FeatureTextWrapper>
              </Feature>
            </FeatureList>
            {/* <HeaderForm onSubmit={handleSubmit}>
              <HeaderButton>Umów wizytę</HeaderButton>
            </HeaderForm>
            <FormSubtitle>
              Umów się na wizytę za pomocą formularza.{" "}
            </FormSubtitle> */}
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header


const FeatureTextWrapper = styled.div`
  margin: 0 0 0 10px;
  padding: 0px;
  font-size: 17px;
`

const Feature = styled.li`
  padding: 20px 0px 5px 0px;
  display: flex;
  align-items: items-center;
  img {
    height: 30px;
    width: 30px;
  }
`

const FeatureList = styled.ul`
  padding: 0;
  line-height: 2;
  margin: 0px;
`

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 145px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  margin-top: 45px;
  font-size: 20px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin-top: 26px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      
      margin-left: 34px;
    }
  }

  h1 {
    margin: 0 0 14px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

// const HeaderForm = styled.form`
//   display: flex;
//   flex-direction: row;
//   padding-bottom: 16px;

//   @media (max-width: ${(props) => props.theme.screen.sm}) {
//     flex-direction: column;
//   }
// `

// const FormSubtitle = styled.span`
//   ${(props) => props.theme.font_size.xxsmall}
// `

// const FormSubtitleLink = styled(Link)`
//   color: ${(props) => props.theme.color.secondary};
//   padding-bottom: 1px;
//   margin-left: 8px;
//   text-decoration: none;
//   border-bottom: 1px solid ${(props) => props.theme.color.secondary};
// `

// const HeaderInput = styled.input`
//   font-weight: 500;
//   font-size: 16px;
//   color: ${(props) => props.theme.color.primary};
//   line-height: 42px;
//   width: 100%;
//   text-align: left;
//   height: 60px;
//   border-width: 1px;
//   border-style: solid;
//   border-color: ${(props) => props.theme.color.secondary};
//   border-image: initial;
//   border-radius: 4px;
//   padding: 8px 16px;
//   outline: 0px;
//   &:focus {
//     box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.md}) {
//     margin-bottom: 8px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.sm}) {
//     display: block;
//     width: 100%;
//   }
// `

// const HeaderButton = styled.button`
//   font-weight: 500;
//   font-size: 14px;
//   color: white;
//   letter-spacing: 1px;
//   height: 60px;
//   display: block;
//   margin-left: 8px;
//   text-transform: uppercase;
//   cursor: pointer;
//   white-space: nowrap;
//   background: ${(props) => props.theme.color.secondary};
//   border-radius: 4px;
//   padding: 0px 40px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   outline: 0px;

//   &:hover {
//     box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.md}) {
//   }
//   @media (max-width: ${(props) => props.theme.screen.sm}) {
//     margin-left: 0;
//   }
// `
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  border-radius: 2%;
  width: 450px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
