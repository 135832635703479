import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="rehabilitacja">
    <StyledContainer>
      <Subtitle>Rehabilitacja</Subtitle>
      <SectionTitle>Główne specjalizacje leczenia</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Rehabilitacja ortopedyczna</FeatureTitle>
          <FeatureText>
            Przed i pooperacyjna. <br /> Urazy, złamania i problemy natury
            przeciążeniowej.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Terapia manualna Metodą Mulligana</FeatureTitle>
          <FeatureText>
            Ból podczas wykonywania ruchu. <br /> Ograniczenia ruchomości.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Rehabilitacja sportowa</FeatureTitle>
          <FeatureText>
            Profilaktyka przeciwurazowa. <br /> Powrót do sportu po kontuzji lub
            operacji.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Opieka sportowa</FeatureTitle>
          <FeatureText>
            Przygotowanie do zawodów. <br /> Trening motoryczny/funkcjonalny.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Bóle kręgosłupa</FeatureTitle>
          <FeatureText>
            <br />
            Choroba zwyrodnieniowa, dyskopatia. <br /> Rwa kulszowa, udowa,
            ramienna.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Bóle kończyn i stawów obwodowych</FeatureTitle>
          <FeatureText>
            Ograniczenia ruchomości. <br /> Drętwienia, mrowienia, problemy z
            prawidłowym ukrwieniem.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h2`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  align-items: top;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  text-align: center;
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
