import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"
import FaqItem from "../../components/common/faq/faqitem"

const FAQS = [
  {
    title: "Jak wygląda pierwsza wizyta?",
    content: () => (
      <>
        Pierwsza wizyta charakteryzuje się szczegółowym badaniem oraz wywiadem.
        Należy zabrać ze sobą wszystkie dokumenty oraz historię choroby. Następnie przechodzimy do badania w postaci testów diagnostycznych,
        prowokacyjnych, testów ruchomości oraz testów nerwowo-mięśniowych.
        Po zebraniu najważniejszych informacji przechodzimy do terapii.
      </>
    ),
  },
  {
    title: "Co powienienem zabrać ze sobą na pierwszą wizytę?",
    content: () => (
      <>
        Na pierwszą wizytę należy zabrać ze sobą pełna dokumentację medyczną.
        Oczywiście brak dokumentacji medycznej nie jest przeszkodą do terapii,
        jednak jeżeli posiadamy opisy lub zdjęcia możemy zabrać je ze sobą.
        Ważny jest również strój i buty na zmianę. Wygodny ubiór pozwoli nam na
        łatwiejsza pracę oraz swobodniejsze wykonywanie ćwiczeń.
      </>
    ),
  },
  {
    title: "Ile czasu trwa jedna wizyta?",
    content: () => (
      <>
        Wizyta trwa 45 min do godziny w zależności od rozległości problemu z
        którym zgłosi się pacjent. Jest to według mnie optymalny czas, abym mógł
        poznać historię, przyjrzeć się problemowi oraz zaproponować rozwiązanie.
      </>
    ),
  },
  {
    title: "Ile potrzeba wizyt, aby odczuć poprawę?",
    content: () => (
      <>
        Odpowiedz na to pytanie zależy od wielu czynników. Każdy pacjent jest
        inny jak również jego problem z którym przychodzi. Jeżeli problem jest
        od dłuższego czasu będzie potrzeba więcej pracy, natomiast jeżeli jest
        to świeża kontuzja lub uraz prawdopodobnie dojście do stanu sprzed
        zdarzenia zajmie nam mniej spotkań. Poprawa moze nastąpić już po
        pierwszej wizycie, lecz aby była ona długotrwała należy zmienić niektóre
        nasze nawyki, nauczyć się prawidłowego ruchu oraz włożyć w to dużo
        pracy.
      </>
    ),
  },
]

const Faq = () => (
  <StyledSection id="faq">
    <GetStartedContainer>
      <GetStartedTitle>Najczęściej zadawane pytania</GetStartedTitle>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </GetStartedContainer>
  </StyledSection>
)

export default Faq

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.white};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

// const TryItButton = styled.button`
//   font-weight: 500;
//   font-size: 14px;
//   color: white;
//   letter-spacing: 1px;
//   height: 60px;
//   display: block;
//   margin-left: 8px;
//   text-transform: uppercase;
//   cursor: pointer;
//   white-space: nowrap;
//   background: ${(props) => props.theme.color.secondary};
//   border-radius: 4px;
//   padding: 0px 40px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   outline: 0px;
//   &:hover {
//     box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.md}) {
//   }
//   @media (max-width: ${(props) => props.theme.screen.sm}) {
//     margin-left: 0;
//   }
// `

// const Subtitle = styled.span`
//   ${(props) => props.theme.font_size.xxsmall}
//   padding-top: 16px;
//   font-size: 14px;
//   color: ${(props) => props.theme.color.primary};
// `
