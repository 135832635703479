import React from "react"
import styled from "styled-components";
import axios from 'axios';
import { Formik, ErrorMessage } from 'formik';
import { Container } from "../global"

import GoogleMap from "../../components/common/googlemap/googlemap"


const ContactWrapper = styled.div`
  padding: 0px 0 20px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const StyledContainer = styled(Container)`
justify-content: center;
margin-top: 40px;
display: grid;
grid-template-columns: 4fr 2fr;
grid-gap: 20px;
text-align: left;
align-items: center;
justify-items: center;
background-color: ${(props) => props.theme.color.background.light};
@media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;
    &:last-child {
      margin-bottom: 24px;
    }
    ${(props) =>
    props.inverse &&
    `
        ${Art} {
          order: 2;
        }
    `}
  }
`
const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`

const StyledInput = styled.input`
  display: block;
  border-radius: 10px;
  border: 2px solid black;
  
  background: none;
  font-size: 20px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
  height: ${({ as }) => (as ? "250px" : "auto")};
  width: ${({ as }) => (as ? "340px" : "340px")};
  margin-bottom: ${({ as }) => as && "20px"};
  :focus{
      outline:none;
  }
`

const StyledLabel = styled.label`
  margin: 10px 0 10px;
  display: block;
  font-size: 15px;
  font-weight: bold;
`


const Button = styled.button`
height: ${({ as }) => (as ? "250px" : "auto")};
  width: ${({ as }) => (as ? "340px" : "340px")};
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #217CB1;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(130, 120, 152, 0.44) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const SectionWraper = styled.div`
    background-color: #f8f8f8;
    padding-top: 35px;
    padding-bottom: 25px;
    
`

const StyledTitle = styled.h3`
  text-align: center;
  margin: 0;

`

const StyledParagraph = styled.p`
  text-align: center;
`



const ContactPage = () => (
  <>
    <SectionWraper id="umów wizytę">
      <StyledContainer >

        <GoogleMap
        />
        <ContactWrapper >
          <Container>
            <StyledTitle>Umów wizytę</StyledTitle>
            <StyledParagraph>Zachęcam do kontaktu przez formularz lub Facebooka</StyledParagraph>
            <Formik
              initialValues={{ name: '', email: '', message: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Wymagany e-mail kontaktowy';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Niewłaściwie wprowadzony e-mail';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                axios.post('https://us-central1-rs-fizjoterapia.cloudfunctions.net/sendEmail', values)
                  .then((res) => {
                    console.log(res);

                    setSubmitting(false);

                  })
                  .catch((err) => {
                    console.log(err);
                    setSubmitting(false);

                  })
              }}
            >
              {({
                values,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
              }) => (
                  <form onSubmit={handleSubmit}>
                    <StyledLabel htmlFor="name" >Imię</StyledLabel>
                    <StyledInput type="text" name="name" id="name" onChange={handleChange} onBlur={handleBlur} value={values.name} />
                    <StyledLabel htmlFor="e-mail">E-mail</StyledLabel>
                    <StyledInput type="e-mail" name="email" id="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                    <ErrorMessage name="email" component="div" />
                    <StyledLabel>Wiadomość</StyledLabel>
                    <StyledInput
                      as="textarea"
                      type="text"
                      name="message"
                      id="message"
                      onChange={handleChange} onBlur={handleBlur} value={values.message}
                    />

                    <Button type="submit">{isSubmitting ? 'Wiadomość została wysłana' : 'Wyślij wiadomość'}</Button>
                  </form>
                )}
            </Formik>

          </Container>
        </ContactWrapper>

      </StyledContainer></SectionWraper>
  </>
)

export default ContactPage
