import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import CheckboxIcon from "../../images/check-box.svg"

import { Container } from "../global"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "rafal2" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <AboutWrapper id="o mnie">
      <Container>
        <Grid>
          <StyledImage fluid={data.file.childImageSharp.fluid} />

          <TextWrapper>
            <TitleWrapper >Rafał Skolmowski</TitleWrapper>
            <ParagraphWrapper>
              Jestem magistrem fizjoterapii i absolwentem Uniwersytetu
              Medycznego w Białymstoku. Podczas mojej 5 letniej nauki
              doskonaliłem swoje umiejętności biorąc udział w praktykach
              zawodowych na Uniwersyteckim Szpitalu Klinicznym oraz Szpitalu w
              Sokółce. Doświadczenie w zawodzie zdobywałem pracując w
              Samodzielnym Publicznym Zakładzie Opieki Zdrowotnej w Sokółce
              między innymi na Oddziale Rehabilitacji oraz w Poradni
              Rehabilitacji. Współpracuje również ze sportowcami indywidualnymi,
              którzy przygotowują się do zawodów różnego szczebla m.in.
              kulturystyka, bench press, pływanie, piłka nożna, siatkówka oraz
              lekkoatletyka.
            </ParagraphWrapper>
            <FinishedTitle>Ukończone szkolenia:</FinishedTitle>
            <FeatureList>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  Terapia Manualna metodą Mulligana MCTA René Claassen
                </FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  Terapia manualna tkanek miękkich
                </FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>Masaż Tkanek Głębokich</FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  Terapia mięśniowo-powięziowych punktów spustowych z
                  diagnostyką funkcjonalną narządu ruchu
                </FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  Complete Sports Manual Theraphy dr. Massimiliano Febbi D.O
                </FeatureTextWrapper>
              </Feature>
            </FeatureList>

          </TextWrapper>
        </Grid>
      </Container>
    </AboutWrapper>
  )
}

const TitleWrapper = styled.h1`

  font-size: 40px;
  padding: 0px 0 0px 0;
  margin: 20 0 0 0px;
  text-align: left;
`

const FeatureTextWrapper = styled.div`
  margin: 0 0 0 10px;
  padding: 0px;
  font-size: 15px;
  align-items: center;
`

const Feature = styled.li`
  padding: 5px 0px 5px 0px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  img {
    align-items: center;
    height: 16px;
    width: 16px;
  }
`

const FinishedTitle = styled.h4`
  margin: 30px 0px 10px 0px;
`

const ParagraphWrapper = styled.p`
  font-size: 15px;
  margin-top: 10px;
`

const FeatureList = styled.ul`
  padding: 0;
  line-height: 2;
  margin: 0px;
`

const TextWrapper = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

const StyledImage = styled(Img)`

  margin: 110px 0 40px 0;
  border-radius: 1%;
  width: 450px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;
  ${(props) =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}
  h2 {
    margin-bottom: 16px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;
    &:last-child {
      margin-bottom: 24px;
    }
    ${(props) =>
    props.inverse &&
    `
        ${Art} {
          order: 2;
        }
    `}
  }
`

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`
const AboutWrapper = styled.div`
  background-color: #f8f8f8;
  padding: 0px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

export default About
